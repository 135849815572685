import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	ChevronDownIcon,
	Cross2Icon,
	DotsHorizontalIcon,
	ImageIcon,
	MixerHorizontalIcon,
	Pencil1Icon,
	StarIcon,
	TrashIcon,
	CaretSortIcon,
} from "@radix-ui/react-icons";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetFooter,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "src/components/ui/sheet";
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "src/components/ui/drawer";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import { Separator } from "src/components/ui/separator";
import { Switch } from "src/components/ui/switch";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../../components/ui/table";
import {
	SageImage,
	SageProduct,
	useProducts,
} from "../../helper/ProductContext";
import {
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
} from "src/components/ui/accordion";
import { Checkbox } from "src/components/ui/checkbox";
import { Skeleton } from "src/components/ui/skeleton";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/components/ui/select";

const ProductTable: React.FC = () => {
	const {
		productList = [],
		fetchProducts,
		searchProducts,
		deleteImage,
		uploadImages,
		updateProductStatus,
		fetchProductGroups,
		updatePriceStatus,
		productGroups,
		totalPages,
		totalProducts,
		currentPage,
		setCurrentPage,
	} = useProducts();

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
		undefined
	);
	const [filters, setFilters] = useState({
		active: false,
		inactive: false,
		showPrice: false,
		requestPrice: false,
	});
	const [sortBy, setSortBy] = useState<string>("articleId");
	const [loading, setLoading] = useState<boolean>(false);
	const [sortDirection, setSortDirection] = useState<string>("ASC");
	const [selectedSort, setSelectedSort] = useState<string | undefined>(
		undefined
	);

	const scrollToTop = useCallback(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	const memoizedFetchProducts = useCallback(() => {
		const statusFilters: string[] = [];

		if (filters.active) statusFilters.push("active=true");
		if (filters.inactive) statusFilters.push("active=false");
		if (filters.showPrice) statusFilters.push("showPrice=true");
		if (filters.requestPrice) statusFilters.push("showPrice=false");

		const statusFilterString = statusFilters.join("&");

		fetchProducts(
			currentPage,
			sortBy,
			sortDirection,
			selectedGroup,
			statusFilterString
		);
	}, [currentPage, sortBy, sortDirection, selectedGroup, filters]);

	const memoizedSearchProducts = useCallback(
		(searchTerm: string) => {
			searchProducts(searchTerm, 1);
		},
		[searchProducts]
	);

	useEffect(() => {
		if (searchTerm) {
			const delayDebounceFn = setTimeout(() => {
				memoizedSearchProducts(searchTerm);
			}, 500);

			return () => clearTimeout(delayDebounceFn);
		} else {
			memoizedFetchProducts();
			fetchProductGroups();
		}
	}, [searchTerm, selectedGroup, selectedSort, currentPage, filters]);

	const handleImageChange = useCallback(
		(articleId: number, files: FileList | null) => {
			if (files && files.length > 0) {
				const images = Array.from(files);
				uploadImages(articleId, images);
				setLoading(true);
				setTimeout(() => {
					memoizedFetchProducts();
					setLoading(false);
				}, 3000);
			}
		},
		[uploadImages, memoizedFetchProducts]
	);

	const handleDelete = useCallback(
		(imageId: number | undefined, articleId: number) => {
			if (typeof imageId === "number") {
				deleteImage(imageId, articleId);
				memoizedFetchProducts();
			} else {
				console.error("Invalid imageId: ", imageId);
			}
		},
		[deleteImage, memoizedFetchProducts]
	);

	const handleGroupChange = useCallback(
		(value: string) => {
			if (value !== selectedGroup) {
				setCurrentPage(1);
				setSelectedGroup(value);
			}
		},
		[selectedGroup, setCurrentPage]
	);

	const handleFilterChange = useCallback(
		(filter: "active" | "inactive" | "showPrice" | "requestPrice", checked: boolean) => {
			const newFilters = { ...filters };
			newFilters[filter] = checked;
	
			switch (filter) {
				case "active":
					if (checked) newFilters.inactive = false;
					break;
				case "inactive":
					if (checked) newFilters.active = false;
					break;
				case "showPrice":
					if (checked) newFilters.requestPrice = false;
					break;
				case "requestPrice":
					if (checked) newFilters.showPrice = false;
					break;
				default:
					break;
			}
	
			setCurrentPage(1);
			setFilters(newFilters);
		},
		[filters]
	);

	const handleSortChange = useCallback(
		(field: string) => {
			const isAsc = sortBy === field && sortDirection === "ASC";
			setSortBy(field);
			setSortDirection(isAsc ? "DESC" : "ASC");
			setSelectedSort(`${field}-${isAsc ? "DESC" : "ASC"}`);
		},
		[sortBy, sortDirection]
	);

	const handlePageSelectInc = useCallback(() => {
		const newPage =
			currentPage < totalPages ? currentPage + 1 : currentPage;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, totalPages, setCurrentPage, scrollToTop]);

	const handlePageSelectDec = useCallback(() => {
		const newPage = currentPage > 1 ? currentPage - 1 : 1;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectFirst = useCallback(() => {
		if (currentPage !== 1) {
			setCurrentPage(1);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectLast = useCallback(() => {
		if (currentPage !== totalPages) {
			setCurrentPage(totalPages);
			scrollToTop();
		}
	}, [totalPages, currentPage, setCurrentPage, scrollToTop]);

	const handleSearch = useCallback(() => {
		memoizedSearchProducts(searchTerm);
	}, [searchTerm, memoizedSearchProducts]);

	const handleResetFilters = useCallback(() => {
		setSelectedGroup(undefined);
		setSelectedSort(undefined);
		setSortDirection("ASC");
		setSortBy("articleId");
		setSearchTerm("");
		setFilters({
			active: false,
			inactive: false,
			showPrice: false,
			requestPrice: false,
		});
		memoizedFetchProducts();
	}, [memoizedFetchProducts]);

	const renderPageNumbers = useMemo(() => {
		return (
			<div className="flex items-center justify-center">{`Seite ${currentPage} von ${totalPages}`}</div>
		);
	}, [currentPage, totalPages]);

	const ProductImageCell: React.FC<{
		imageUrl: string;
		articleId: number;
	}> = ({ imageUrl, articleId }) => {
		const [isLoading, setIsLoading] = useState(true);

		return (
			<TableCell className="font-medium">
				{imageUrl ? (
					<div className="relative">
						{isLoading && (
							<Skeleton className="h-16 w-16 absolute top-0 left-0 rounded-lg" />
						)}
						<img
							src={imageUrl}
							alt={articleId.toString()}
							width={48}
							height={48}
							className={`w-16 h-16 object-cover ${
								isLoading ? "opacity-0" : "opacity-100"
							}`}
							onLoad={() => setIsLoading(false)}
						/>
					</div>
				) : (
					<div className="border flex items-center justify-center h-16 w-16">
						<ImageIcon className="w-6 h-6 text-sidebarGray-500" />
					</div>
				)}
			</TableCell>
		);
	};

	const getSortIcon = (field: string) => {
		if (sortBy === field) {
			return sortDirection === "ASC" ? (
				<ChevronUpIcon className="ml-2" />
			) : (
				<ChevronDownIcon className="ml-2" />
			);
		} else {
			return <CaretSortIcon className="ml-2" />;
		}
	};

	return (
		<>
			<div className="flex items-center justify-center gap-4 px-2 py-6 lg:px-0">
				<div className="w-full flex items-center justify-center gap-4 px-2 lg:px-0">
					<Sheet>
						<SheetTrigger asChild>
							<Button
								variant="default"
								className="flex items-center justify-center gap-2"
							>
								Filter
								<MixerHorizontalIcon />
							</Button>
						</SheetTrigger>
						<SheetContent side={"left"} className="z-[70]">
							<SheetHeader>
								<SheetTitle className="text-2xl">
									Filter
								</SheetTitle>
							</SheetHeader>
							<div>
								<div className="flex flex-col items-start justify-start mt-6">
									<div className="w-full flex items-start justify-start gap-4 flex-wrap">
										<Accordion
											type="single"
											collapsible
											className="w-full border-[1px] border-sidebarGray-700 rounded-lg px-2"
										>
											<AccordionItem value="item-1 w-full">
												<AccordionTrigger className="px-1">
													Produktstatus
												</AccordionTrigger>
												<AccordionContent className="p-3">
													<div className="flex flex-col gap-2 w-full">
														<div className="flex items-center gap-2">
															<Checkbox
																id="active"
																checked={
																	filters.active
																}
																onCheckedChange={(
																	checked
																) =>
																	handleFilterChange(
																		"active",
																		checked as boolean
																	)
																}
															/>
															<Label htmlFor="active">
																Aktive Artikel
															</Label>
														</div>
														<div className="flex items-center gap-2">
															<Checkbox
																id="inactive"
																checked={
																	filters.inactive
																}
																onCheckedChange={(
																	checked
																) =>
																	handleFilterChange(
																		"inactive",
																		checked as boolean
																	)
																}
															/>
															<Label htmlFor="inactive">
																Inaktive Artikel
															</Label>
														</div>
														<div className="flex items-center gap-2">
															<Checkbox
																id="showPrice"
																checked={
																	filters.showPrice
																}
																onCheckedChange={(
																	checked
																) =>
																	handleFilterChange(
																		"showPrice",
																		checked as boolean
																	)
																}
															/>
															<Label htmlFor="showPrice">
																Festpreis
															</Label>
														</div>
														<div className="flex items-center gap-2">
															<Checkbox
																id="requestPrice"
																checked={
																	filters.requestPrice
																}
																onCheckedChange={(
																	checked
																) =>
																	handleFilterChange(
																		"requestPrice",
																		checked as boolean
																	)
																}
															/>
															<Label htmlFor="requestPrice">
																Preis auf
																Anfrage
															</Label>
														</div>
													</div>
												</AccordionContent>
											</AccordionItem>
										</Accordion>
									</div>
								</div>
								{/* <Select
									value={selectedSort}
									onValueChange={handleSortChange}
								>
									<SelectTrigger className="w-full mt-6">
										<SelectValue placeholder="Sortierung" />
									</SelectTrigger>
									<SelectContent className="z-[71]">
										<SelectGroup>
											<SelectLabel>
												Sortierung
											</SelectLabel>
											<SelectItem value="price-asc">
												Preis aufsteigend
											</SelectItem>
											<SelectItem value="price-desc">
												Preis absteigend
											</SelectItem>
											<SelectItem value="searchTerm-asc">
												A - Z
											</SelectItem>
											<SelectItem value="searchTerm-desc">
												Z - A
											</SelectItem>
											<SelectItem value="available-asc">
												Bestand aufsteigend
											</SelectItem>
											<SelectItem value="available-desc">
												Bestand absteigend
											</SelectItem>
										</SelectGroup>
									</SelectContent>
								</Select> */}
								<Select
									value={selectedGroup}
									onValueChange={handleGroupChange}
								>
									<SelectTrigger className="w-full mt-6">
										<SelectValue placeholder="Warengruppe" />
									</SelectTrigger>
									<SelectContent className="z-[90]">
										<SelectGroup>
											<SelectLabel>
												Warengruppe
											</SelectLabel>
											{productGroups.map((group) => (
												<SelectItem
													value={group}
													key={group}
												>
													{group}
												</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<SheetFooter className="flex-col items-center justify-center mt-8">
								<SheetClose asChild>
									<Button type="submit" className="w-full">
										Schließen
									</Button>
								</SheetClose>
							</SheetFooter>
							<Button
								variant="ghost"
								className="w-full mt-4"
								onClick={handleResetFilters}
							>
								Filter zurücksetzen
							</Button>
						</SheetContent>
					</Sheet>
					<Input
						type="text"
						placeholder="Suche..."
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<Button
						className="w-max"
						variant={"default"}
						onClick={handleSearch}
					>
						Suche
					</Button>
				</div>
			</div>
			<div className=" flex items-center justify-start gap-2">
				<Badge className="bg-transparent h-8 w-0 p-0 m-0 shadow-none"></Badge>
				{selectedSort && (
					<Badge
						className="flex items-center justify-between gap-1 w-max"
						variant={"default"}
					>
						{sortBy === "price" && <p>Preis</p>}
						{sortBy === "searchTerm" && <p>Name</p>}
						{sortDirection === "DESC" && <p>absteigend</p>}
						{sortDirection === "ASC" && <p>aufsteigend</p>}
						<button
							onClick={() => (
								setSelectedSort(undefined),
								setSortBy(""),
								setSortDirection("")
							)}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
				{Object.entries(filters).map(([key, value]) => {
					if (!value) return null;
					let label = "";
					switch (key) {
						case "active":
							label = "Aktive Artikel";
							break;
						case "inactive":
							label = "Inaktive Artikel";
							break;
						case "showPrice":
							label = "Festpreis";
							break;
						case "requestPrice":
							label = "Preis auf Anfrage";
							break;
						default:
							break;
					}
					return (
						<Badge
							key={key}
							className="flex items-center justify-between gap-1 w-max"
							variant={"default"}
						>
							{label}
							<button
								onClick={() =>
									setFilters((prevFilters) => ({
										...prevFilters,
										[key]: false,
									}))
								}
								className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
							>
								<Cross2Icon />
							</button>
						</Badge>
					);
				})}
				{selectedGroup && (
					<Badge className="flex items-center justify-between gap-2 w-max">
						{selectedGroup}
						<button
							onClick={() => setSelectedGroup(undefined)}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
				{searchTerm && (
					<Badge className="flex items-center justify-between gap-2 w-max">
						{searchTerm}
						<button
							onClick={() => setSearchTerm("")}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
			</div>
			{Array.isArray(productList) && productList.length > 0 ? (
				<div className="flex h-12 w-full items-center justify-end gap-4 -space-x-px text-sm">
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectFirst}
					>
						Start
					</button>
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectDec}
					>
						<ChevronLeftIcon />
						Zurück
					</button>
					{renderPageNumbers}
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectInc}
					>
						Weiter
						<ChevronRightIcon />
					</button>
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectLast}
					>
						Ende
					</button>
				</div>
			) : (
				<></>
			)}
			<Table className="px-8 z-40">
				<TableHeader className="sticky">
					<TableRow>
						<TableHead className="w-[100px]"></TableHead>
						<TableHead
							onClick={() => handleSortChange("articleId")}
							className="cursor-pointer w-[100px]"
						>
							<div className="flex items-center">
								Art.-Nr.
								{getSortIcon("articleId")}
							</div>
						</TableHead>
						<TableHead className="w-[180px]">Status</TableHead>
						<TableHead
							onClick={() => handleSortChange("searchTerm")}
							className="cursor-pointer flex items-center"
						>
							<div className="flex items-center">
								Name
								{getSortIcon("searchTerm")}
							</div>
						</TableHead>
						<TableHead>Kategorie</TableHead>
						<TableHead
							onClick={() => handleSortChange("price")}
							className="cursor-pointer w-[100px] px-0"
						>
							<div className="flex items-center text-right justify-end">
								Preis
								{getSortIcon("price")}
							</div>
						</TableHead>
						<TableHead
							onClick={() => handleSortChange("available")}
							className="cursor-pointer w-[100px] text-right px-0"
						>
							<div className="flex items-center justify-end">
								Bestand
								{getSortIcon("available")}
							</div>
						</TableHead>
						<TableHead className="text-right w-[150px]"></TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{Array.isArray(productList) && productList.length > 0 ? (
						productList.map((product) => (
							<TableRow
								key={product.articleId}
								className="dark:text-white"
							>
								<ProductImageCell
									imageUrl={product.images[0]?.imageUrl || ""}
									articleId={product.articleId}
								/>
								<TableCell className="font-medium text-center">
									{product.articleId}
								</TableCell>
								<TableCell className="font-medium flex items-center h-full justify-start gap-2">
									{product.active ? (
										<Badge className="bg-green-950 text-green-300">
											Aktiv
										</Badge>
									) : (
										<Badge className="bg-gray-900 text-gray-300">
											Inaktiv
										</Badge>
									)}
									{product.showPrice ? (
										<Badge className="bg-blue-950 text-blue-300">
											Festpreis
										</Badge>
									) : (
										<Badge className="bg-orange-950 text-orange-300">
											Anfrage
										</Badge>
									)}
								</TableCell>
								<TableCell className="font-medium">
									{product.searchTerm}
								</TableCell>
								<TableCell>{product.productGroup}</TableCell>
								<TableCell className="font-medium text-right">
									{product.price}€
								</TableCell>
								<TableCell className="text-right">
									{product.available}
								</TableCell>
								<TableCell className="h-full flex items-center justify-center gap-2">
									<Drawer>
										<DrawerTrigger
											asChild
											className="w-12 h-12"
										>
											<Button variant="outline">
												<Pencil1Icon className="w-5 h-5" />
											</Button>
										</DrawerTrigger>
										<DrawerContent className="h-[95vh] px-12">
											<DrawerHeader className="flex flex-col">
												<DrawerTitle className="text-3xl flex flex-col items-start justify-start p-0 m-0">
													{product.searchTerm}
												</DrawerTitle>
												<DrawerDescription>
													{product.id}
												</DrawerDescription>
											</DrawerHeader>
											<section className="flex items-start justify-start gap-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-sidebarGray-700 scrollbar-track-sidebarGray-800 custom-scrollbar overflow-x-hidden">
												<div className="w-2/3 flex flex-col items-start justify-start gap-4">
													<Card className="w-full flex flex-col py-8 pl-16 pr-16 items-start justify-start">
														<div className="flex flex-col w-full items-start gap-1.5 pl-1">
															<span className="text-base text-gray-500 w-full">
																Bilder hochladen
															</span>
															<div className="flex items-center justify-center gap-8 w-full">
																<input
																	className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-sidebarGray-700"
																	id="file_input"
																	type="file"
																	multiple
																	onChange={(
																		e
																	) =>
																		handleImageChange(
																			product.articleId,
																			e
																				.target
																				.files
																		)
																	}
																/>
																{loading && (
																	<div className="grid w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
																		<svg
																			className="text-gray-300 animate-spin"
																			viewBox="0 0 64 64"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																			width="24"
																			height="24"
																		>
																			<path
																				d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
																				stroke="currentColor"
																				stroke-width="5"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																			></path>
																			<path
																				d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
																				stroke="currentColor"
																				stroke-width="5"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																				className="text-gray-900"
																			></path>
																		</svg>
																	</div>
																)}
															</div>
														</div>
													</Card>
													<Card className="w-full flex flex-col py-8 pl-16 pr-16 items-start justify-start">
														<span className="text-base text-gray-500 pl-1 pt-1">
															Produktbilder
														</span>
														<div className="grid grid-cols-3 gap-2 mt-4">
															{product.images
																.length ===
															0 ? (
																<ImageIcon className="w-96 h-96 text-sidebarGray-500" />
															) : (
																product.images.map(
																	(
																		image: SageImage
																	) => (
																		<div
																			key={
																				image.id
																			}
																			className="relative border-sidebarGray-500 border-[1px]"
																		>
																			<img
																				src={`${image.imageUrl}`}
																				alt={
																					product.searchTerm
																				}
																				className="w-full h-full max-h-[250px] object-contain"
																			/>
																			<Button
																				onClick={() =>
																					handleDelete(
																						image.id,
																						product.articleId
																					)
																				}
																				className="absolute top-2 right-2 px-2 py-0 m-0"
																				variant="ghost"
																			>
																				<TrashIcon className="w-4 h-4" />
																			</Button>
																		</div>
																	)
																)
															)}
														</div>
													</Card>
												</div>
												<div className="flex flex-col w-1/3 gap-4">
													<Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start">
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																ID
															</span>
															<p className="text-lg">
																{
																	product.articleId
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Produktname
															</span>
															{product.searchTerm ? (
																<p className="text-lg">
																	{
																		product.searchTerm
																	}
																</p>
															) : (
																<p>-</p>
															)}
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Beschreibung
															</span>
															{product.description ? (
																<p className="text-lg">
																	{
																		product.description
																	}
																</p>
															) : (
																<p>-</p>
															)}
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Details
															</span>
															<p className="text-lg">
																{
																	product.additionalInfo
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Bestand
															</span>
															<p className="text-lg">
																{
																	product.available
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Gewicht
															</span>
															<p className="text-lg">
																{product.weight}
																&nbsp;
																{
																	product.weightUnit
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Preis
															</span>
															<p className="text-lg">
																{product.price}
																&nbsp;
																{
																	product.basePriceUnit
																}
															</p>
														</div>
													</Card>
													<Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start">
														<div className="w-full flex-col flex items-start justify-start gap-2">
															<div className="flex items-center justify-between w-full rounded-md p-2">
																<Label htmlFor="webshop-mode">
																	Webshop-Artikel
																</Label>
																<Switch
																	id="webshop-mode"
																	checked={
																		product.active
																	}
																	className="border-sidebarGray-400 border-[1px] text-sidebarGrayLight-800"
																	onCheckedChange={(
																		checked
																	) =>
																		updateProductStatus(
																			product.articleId,
																			checked
																		)
																	}
																/>
															</div>
															<div className="flex items-center justify-between w-full rounded-md p-2">
																<Label htmlFor="price-mode">
																	Preis
																	anzeigen
																</Label>
																<Switch
																	id="price-mode"
																	checked={
																		product.showPrice
																	}
																	className="border-sidebarGray-400 border-[1px] text-sidebarGrayLight-800"
																	onCheckedChange={(
																		checked
																	) =>
																		updatePriceStatus(
																			product.articleId,
																			checked
																		)
																	}
																/>
															</div>
														</div>
													</Card>
												</div>
											</section>
											<DrawerFooter className="flex flex-row items-end justify-end">
												<DrawerClose>
													<Button variant="outline">
														Schließen
													</Button>
												</DrawerClose>
											</DrawerFooter>
										</DrawerContent>
									</Drawer>
									<Popover>
										<PopoverTrigger
											asChild
											className="w-12 h-12"
										>
											<Button variant="outline">
												<DotsHorizontalIcon className="w-5 h-5" />
											</Button>
										</PopoverTrigger>
										<PopoverContent className="w-56">
											<div className="flex items-center justify-start gap-2">
												<StarIcon />
												<h1>Schnellzugriff</h1>
											</div>
											<Separator className="my-2" />
											<div className="w-full flex-col flex items-start justify-start gap-2">
												<div className="flex items-center justify-between w-full rounded-md p-2">
													<Label htmlFor="webshop-mode">
														Webshop-Artikel
													</Label>
													<Switch
														id="webshop-mode"
														checked={product.active}
														className="border-sidebarGray-400 border-[1px] text-sidebarGrayLight-800"
														onCheckedChange={(
															checked
														) =>
															updateProductStatus(
																product.articleId,
																checked
															)
														}
													/>
												</div>
												<div className="flex items-center justify-between w-full rounded-md p-2">
													<Label htmlFor="price-mode">
														Preis anzeigen
													</Label>
													<Switch
														id="price-mode"
														checked={
															product.showPrice
														}
														className="border-sidebarGray-400 border-[1px] text-sidebarGrayLight-800"
														onCheckedChange={(
															checked
														) =>
															updatePriceStatus(
																product.articleId,
																checked
															)
														}
													/>
												</div>
											</div>
										</PopoverContent>
									</Popover>
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={8}
								className="text-center py-12 text-xl"
							>
								Es wurden keine Produkte gefunden
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			{Array.isArray(productList) && productList.length > 0 ? (
				<div className="flex h-12 w-full items-center justify-center gap-4 -space-x-px text-sm py-12">
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectFirst}
					>
						Start
					</button>
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectDec}
					>
						<ChevronLeftIcon />
						Zurück
					</button>
					{renderPageNumbers}
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectInc}
					>
						Weiter
						<ChevronRightIcon />
					</button>
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectLast}
					>
						Ende
					</button>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default ProductTable;
