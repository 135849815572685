import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useState,
	useEffect,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export interface CustomImageFile {
	id: number;
	imageUrl: string;
	product: CustomProduct;
	articleId: number;
}

export interface CustomProduct {
	articleId: number;
	description: string;
	unit: string;
	searchTerm: string;
	additionalInfo: string;
	price: number;
	barcode: string;
	weight: number;
	weightUnit: string;
	basePriceUnit: string;
	available: boolean;
	productGroup: string;
	images: CustomImageFile[];
	active: boolean;
	showPrice: boolean;
}

interface CustomProductContextType {
	customProductList: CustomProduct[];
	createProduct: (
		articleId: string,
		description?: string,
		unit?: string,
		searchTerm?: string,
		additionalInfo?: string,
		price?: number,
		barcode?: number,
		weight?: number,
		weightUnit?: string,
		basePriceUnit?: string,
		available?: boolean,
		productGroup?: string,
		showPrice?: boolean,
		active?: boolean
	) => Promise<void>;
	productGroups: string[];
	fetchCustomProducts: (
		page?: number,
		sortBy?: string,
		sortDirection?: string,
		productGroup?: string,
		sortStatus?: string
	) => Promise<void>;
	deleteProduct: (articleId: number) => void;
	searchProducts: (
		term: string,
		page?: number,
		sortBy?: string,
		sortDirection?: string
	) => void;
	uploadImages: (articleId: number, images: File[]) => Promise<void>;
	fetchProductGroups: () => void;
	totalPages: number;
	totalProducts: number;
	currentPage: number;
	setCurrentPage: (page: number) => void;
}

export const CustomProductContext = createContext<CustomProductContextType>(
	{} as CustomProductContextType
);

export const CustomProductProvider: React.FC<PropsWithChildren<object>> = ({
	children,
}) => {
	const [customProductList, setCustomProductList] = useState<CustomProduct[]>(
		[]
	);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalProducts, setTotalProducts] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [productGroups, setProductGroups] = useState<string[]>([]);
	const navigate = useNavigate();

	async function createProduct(
		articleId: string,
		description?: string,
		unit?: string,
		searchTerm?: string,
		additionalInfo?: string,
		price?: number,
		barcode?: number,
		weight?: number,
		weightUnit?: string,
		basePriceUnit?: string,
		available: boolean = false,
		productGroup?: string,
		showPrice: boolean = false,
		active: boolean = false
	) {
		try {
			const token = localStorage.getItem("authToken");
			if (!token) {
				console.error("No auth token found. Redirecting to login.");
				navigate("/login");
				return;
			}

			const params: any = {
				...(articleId && { articleId }),
				...(description && { description }),
				...(unit && { unit }),
				...(searchTerm && { searchTerm }),
				...(additionalInfo && { additionalInfo }),
				...(weightUnit && { weightUnit }),
				...(basePriceUnit && { basePriceUnit }),
				...(productGroup && { productGroup }),
				...(price && { price }),
				...(barcode && { barcode }),
				...(weight && { weight }),
				active,
				available,
				showPrice,
			};

			const response = await axios.post(`/api/custom/add-product`, {
				params,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				setCustomProductList(response.data.products);
				setTotalPages(response.data.totalPages);
				setTotalProducts(response.data.total);
			} else if (response.status === 401) {
				navigate("/login");
			} else {
				console.error("Unexpected status code:", response.status);
			}
		} catch (error) {
			console.error(
				"Beim Laden der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
			);
		}
	}

	async function fetchCustomProducts(
		page = 1,
		sortBy = "articleId",
		sortDirection = "ASC",
		productGroup: string | null = null,
		sortStatus: string | undefined = undefined
	) {
		try {
			const params: any = {
				page: page.toString(),
				sortBy,
				sortDirection,
				...(productGroup && { productGroup }),
			};

			// Verarbeite die Filter in sortStatus
			if (sortStatus) {
				sortStatus.split("&").forEach((filter) => {
					const [field, value] = filter.split("=");
					if (field && value) {
						params[field] = value;
					}
				});
			}

			const token = localStorage.getItem("authToken");
			if (!token) {
				console.error("No auth token found. Redirecting to login.");
				navigate("/login");
				return;
			}

			const response = await axios.get(`/api/custom/products`, {
				params,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				setCustomProductList(response.data.products);
				setTotalPages(response.data.totalPages);
				setTotalProducts(response.data.total);
			} else if (response.status === 401) {
				navigate("/login");
			} else {
				console.error("Unexpected status code:", response.status);
			}
		} catch (error) {
			console.error(
				"Beim Laden der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
			);
		}
	}

	const deleteProduct = async (articleId: number) => {
		try {
			const token = localStorage.getItem("authToken");
			if (!token) {
				console.error("No auth token found. Redirecting to login.");
				navigate("/login");
				return;
			}

			const response = await axios.delete(
				`/api/custom/delete-product/${articleId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				setCustomProductList((prevList) =>
					prevList.filter(
						(product) => product.articleId !== articleId
					)
				);
			} else {
				console.error("Error deleting product:", response.status);
			}
		} catch (error) {
			console.error("Error deleting product:", error);
		}
	};

	async function searchProducts(
		term = "",
		page = 1,
		sortBy = "price",
		sortDirection = "ASC"
	) {
		try {
			const token = localStorage.getItem("authToken");
			if (!token) {
				console.error("No auth token found. Redirecting to login.");
				navigate("/login");
				return;
			}

			const params = {
				term,
				page: page.toString(),
				sortBy,
				sortDirection,
			};
			const response = await axios.get(`/api/custom/products/search`, {
				params,
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				const data = response.data.products;
				const totalTemp = response.data.total;
				const totalPagesTemp = response.data.totalPages;
				const pageTemp = response.data.page;
				setCustomProductList(data);
				setTotalPages(totalPagesTemp);
				setTotalProducts(totalTemp);
				setCurrentPage(pageTemp);
			}
		} catch (error) {
			console.error(
				"Beim Suchen der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
			);
		}
	}

	const fetchProductGroups = async () => {
		try {
			const token = localStorage.getItem("authToken");
			if (!token) {
				console.error("No auth token found. Redirecting to login.");
				navigate("/login");
				return;
			}

			const response = await axios.get(`/api/custom/product-groups`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				setProductGroups(response.data);
			} else {
				console.error(
					"Error fetching product groups:",
					response.status
				);
			}
		} catch (error) {
			console.error("Error fetching product groups:", error);
		}
	};

	const uploadImages = async (articleId: number, images: File[]) => {
		try {
			const token = localStorage.getItem("authToken");
			if (!token) {
				console.error("No auth token found. Redirecting to login.");
				navigate("/login");
				return;
			}

			const formData = new FormData();
			images.forEach((image) => {
				formData.append("imageFiles", image);
			});
			formData.append("articleId", articleId.toString());

			const response = await axios.post(
				"/api/custom/upload-images",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				const newUploadedImages = response.data.images.map(
					(img: { imageUrl: string; articleId: number }) => ({
						imageUrl: img.imageUrl,
						articleId: img.articleId,
					})
				);

				setCustomProductList((prevList) =>
					prevList.map((product) =>
						product.articleId === articleId
							? {
									...product,
									images: [
										...product.images,
										...newUploadedImages,
									],
							  }
							: product
					)
				);
			} else {
				console.error("Error uploading images:", response.status);
			}
		} catch (error) {
			console.error("Error uploading images:", error);
		}
	};

	// Initiale Produkte laden
	useEffect(() => {
		fetchCustomProducts();
	}, []);

	return (
		<CustomProductContext.Provider
			value={{
				fetchCustomProducts,
				createProduct,
				customProductList,
				productGroups,
				deleteProduct,
				fetchProductGroups,
				searchProducts,
				uploadImages,
				totalPages,
				totalProducts,
				currentPage,
				setCurrentPage,
			}}
		>
			{children}
		</CustomProductContext.Provider>
	);
};

export const useCustomProducts = () => useContext(CustomProductContext);
