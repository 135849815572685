import React, { useEffect, useState, useCallback } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/layout/sidebar/Sidebar";
import TopBar from "../components/layout/topbar/TopBar";
import "./AdminLayout.css";
import { useOrders } from "../helper/OrderContext";
import { useOrdersShop } from "../helper/OrderWebshopContext";
import { useProducts } from "../helper/ProductContext";
import { getUserRoleFromToken } from "src/utils/auth";

const AdminLayout: React.FC = () => {
	const [isSidebarExpanded, setSidebarExpanded] = useState(true);
	const { fetchOrders } = useOrders();
	const { fetchOrdersShop } = useOrdersShop();
	const userRole = getUserRoleFromToken();

	const fetchData = useCallback(() => {
		if (userRole === "bucher") {
			fetchOrders("pending");
			fetchOrders("picked");
			fetchOrders("packed");
			fetchOrders("shipped");
			fetchOrders("returned");
		}
		fetchOrdersShop("pending");
		fetchOrdersShop("picked");
		fetchOrdersShop("packed");
		fetchOrdersShop("sent");
		fetchOrdersShop("returned");
	}, [fetchOrders, fetchOrdersShop, userRole]);

	useEffect(() => {
		fetchData(); // Initial fetch
		const interval = setInterval(fetchData, 360000);
		return () => clearInterval(interval);
	}, [fetchData]);

	return (
		<div
			style={{ display: "flex", height: "100vh", overflow: "hidden" }}
			className="bg-white dark:bg-sidebarGray-800"
		>
			<Sidebar
				isSidebarExpanded={isSidebarExpanded}
				setSidebarExpanded={setSidebarExpanded}
			/>
			<div
				style={{ flex: 1, overflowY: "scroll" }}
				className="bg-sidebarGrayLight-500 dark:bg-sidebarGray-700"
			>
				<main className="default-layout__content relative">
					<TopBar isSidebarExpanded={isSidebarExpanded} />
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default AdminLayout;
