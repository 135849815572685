import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	ChevronDownIcon,
	Cross2Icon,
	DotsHorizontalIcon,
	ImageIcon,
	MixerHorizontalIcon,
	Pencil1Icon,
	StarIcon,
	CaretSortIcon,
	PlusCircledIcon,
	TrashIcon,
	CheckCircledIcon,
	CheckIcon,
	UploadIcon,
	DownloadIcon,
} from "@radix-ui/react-icons";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "src/components/ui/sheet";
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "src/components/ui/drawer";
import { CustomImageFile } from "../../helper/CustomProductContext";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../../components/ui/table";
import { Skeleton } from "src/components/ui/skeleton";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/components/ui/select";
import { useCustomProducts } from "../../helper/CustomProductContext";
import { Textarea } from "src/components/ui/textarea";

const CustomProductTable: React.FC = () => {
	const scrollToTop = useCallback(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);
	const {
		customProductList = [],
		fetchCustomProducts,
		searchProducts,
		uploadImages,
		fetchProductGroups,
		productGroups,
		totalPages,
		currentPage,
		setCurrentPage,
	} = useCustomProducts();

	const [articleId, setArticleId] = useState<string>("");
	const [productName, setProductName] = useState<string>("");
	const [sku, setSku] = useState<string>("");
	const [quantity, setQuantity] = useState<number>(0);
	const [description, setDescription] = useState<string>("");
	const [basePrice, setbasePrice] = useState<number>(0);
	const [weight, setWeight] = useState<number>(0);
	const [productCategory, setProductCategory] = useState<string>("");
	const [imageFiles, setImageFiles] = useState<CustomImageFile[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
		undefined
	);
	const [sortBy, setSortBy] = useState<string>("articleId");
	const [loading, setLoading] = useState<boolean>(false);
	const [sortDirection, setSortDirection] = useState<string>("ASC");
	const [selectedSort, setSelectedSort] = useState<string | undefined>(
		undefined
	);
	const memoizedFetchCustomProducts = useCallback(() => {
		fetchCustomProducts(currentPage, sortBy, sortDirection, selectedGroup);
	}, [currentPage, sortBy, sortDirection, selectedGroup]);
	const memoizedSearchProducts = useCallback(
		(searchTerm: string) => {
			searchProducts(searchTerm, 1);
		},
		[searchProducts]
	);

	useEffect(() => {
		if (searchTerm) {
			const delayDebounceFn = setTimeout(() => {
				memoizedSearchProducts(searchTerm);
			}, 500);

			return () => clearTimeout(delayDebounceFn);
		} else {
			memoizedFetchCustomProducts();
			fetchProductGroups();
		}
	}, [searchTerm, selectedGroup, selectedSort, currentPage]);

	function uploadProduct(
		articleId: string,
		quantity?: number,
		weight?: number,
		basePrice?: number,
		sku?: string,
		productName?: string,
		description?: string,
		productCategory?: string,
		imageFiles?: CustomImageFile[]
	) {}

	const handleImageChange = useCallback(
		(articleId: number, files: FileList | null) => {
			if (files && files.length > 0) {
				const images = Array.from(files);
				uploadImages(articleId, images);
				setLoading(true);
				setTimeout(() => {
					memoizedFetchCustomProducts();
					setLoading(false);
				}, 3000);
			}
		},
		[uploadImages, memoizedFetchCustomProducts]
	);

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		setSelectedImages([...selectedImages, ...files]);
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		setSelectedImages([...selectedImages, ...files]);
		setIsDragging(false); // Zurücksetzen, wenn Dateien fallengelassen werden
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDragEnter = () => {
		setIsDragging(true); // Setzen auf true, wenn Dateien über der Box schweben
	};

	const handleDragLeave = () => {
		setIsDragging(false); // Setzen auf false, wenn Dateien die Box verlassen
	};

	const removeImage = (indexToRemove: number) => {
		setSelectedImages((prevImages) =>
			prevImages.filter((_, index) => index !== indexToRemove)
		);
	};

	const handleGroupChange = useCallback(
		(value: string) => {
			if (value !== selectedGroup) {
				setCurrentPage(1);
				setSelectedGroup(value);
			}
		},
		[selectedGroup, setCurrentPage]
	);

	const handleSortChange = useCallback(
		(field: string) => {
			const isAsc = sortBy === field && sortDirection === "ASC";
			setSortBy(field);
			setSortDirection(isAsc ? "DESC" : "ASC");
			setSelectedSort(`${field}-${isAsc ? "DESC" : "ASC"}`);
		},
		[sortBy, sortDirection]
	);

	const handlePageSelectInc = useCallback(() => {
		const newPage =
			currentPage < totalPages ? currentPage + 1 : currentPage;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, totalPages, setCurrentPage, scrollToTop]);

	const handlePageSelectDec = useCallback(() => {
		const newPage = currentPage > 1 ? currentPage - 1 : 1;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectFirst = useCallback(() => {
		if (currentPage !== 1) {
			setCurrentPage(1);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectLast = useCallback(() => {
		if (currentPage !== totalPages) {
			setCurrentPage(totalPages);
			scrollToTop();
		}
	}, [totalPages, currentPage, setCurrentPage, scrollToTop]);

	const handleSearch = useCallback(() => {
		memoizedSearchProducts(searchTerm);
	}, [searchTerm, memoizedSearchProducts]);

	const handleResetFilters = useCallback(() => {
		setSelectedGroup(undefined);
		setSelectedSort(undefined);
		setSortDirection("ASC");
		setSortBy("articleId");
		setSearchTerm("");
		memoizedFetchCustomProducts();
	}, [memoizedFetchCustomProducts]);

	const renderPageNumbers = useMemo(() => {
		return (
			<div className="flex items-center justify-center">{`Seite ${currentPage} von ${totalPages}`}</div>
		);
	}, [currentPage, totalPages]);

	const ProductImageCell: React.FC<{
		imageUrl: string;
		articleId: number;
	}> = ({ imageUrl, articleId }) => {
		const [isLoading, setIsLoading] = useState(true);

		return (
			<TableCell className="font-medium">
				{imageUrl ? (
					<div className="relative">
						{isLoading && (
							<Skeleton className="h-16 w-16 absolute top-0 left-0 rounded-lg" />
						)}
						<img
							src={imageUrl}
							alt={articleId.toString()}
							width={48}
							height={48}
							className={`w-16 h-16 object-cover ${
								isLoading ? "opacity-0" : "opacity-100"
							}`}
							onLoad={() => setIsLoading(false)}
						/>
					</div>
				) : (
					<div className="border flex items-center justify-center h-16 w-16">
						<ImageIcon className="w-6 h-6 text-sidebarGray-500" />
					</div>
				)}
			</TableCell>
		);
	};

	const getSortIcon = (field: string) => {
		if (sortBy === field) {
			return sortDirection === "ASC" ? (
				<ChevronUpIcon className="ml-2" />
			) : (
				<ChevronDownIcon className="ml-2" />
			);
		} else {
			return <CaretSortIcon className="ml-2" />;
		}
	};

	return (
		<>
			<div className="pb-8 pt-8">
				<h1 className="font-medium text-2xl">
					Administrations- und Content-Verwaltungstool
				</h1>
				<div>
					<Sheet key={"bottom"}>
						<SheetTrigger asChild className="w-max my-8">
							<Button
								variant={"ghost"}
								className="flex items-center justify-center gap-2 border-dotted border-[1px] border-sidebarGray-500 rounded-lg"
							>
								<PlusCircledIcon className="w-5 h-5" />
								Produkt hinzufügen
							</Button>
						</SheetTrigger>
						<SheetContent
							className="h-[95vh] px-[20%] m-0 overflow-y-scroll"
							side={"bottom"}
						>
							<SheetHeader className="flex flex-col p-0 m-0">
								<SheetTitle className="text-3xl py-8 flex flex-col items-start justify-start p-0 m-0">
									Neues Produkt hinzufügen
								</SheetTitle>
								<SheetDescription></SheetDescription>
							</SheetHeader>
							<section className="flex items-start justify-start gap-4 p-0 m-0">
								<div className="grid grid-cols-1 w-3/5 gap-4">
									<Card className="w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start flex-grow">
										<h3 className="mb-2 text-lg font-medium">
											Artikelinformationen
										</h3>
										<div className="flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="articleId">
													Art.-Nr.
												</label>
												<Input id="produktname"></Input>
											</span>
											<span className="w-full">
												<label htmlFor="sku">SKU</label>
												<Input id="sku"></Input>
											</span>
											<span className="w-full">
												<label htmlFor="quantity">
													Bestand
												</label>
												<Input
													id="quantity"
													type="number"
												></Input>
											</span>
										</div>
									</Card>
									<Card className="flex flex-col w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">
											Allgemeine Informationen
										</h3>
										<div className="w-full flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="produktname">
													Produktname
												</label>
												<Input id="produktname"></Input>
											</span>
										</div>
										<div className="w-full flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="description">
													Beschreibung
												</label>
												<Textarea
													placeholder="Beschreiben Sie ihr Produkt..."
													id="description"
												/>
											</span>
										</div>
									</Card>
									<Card className="w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">
											Bilder hochladen
										</h3>
										<div
											className={`border-2 border-dashed border-gray-400 p-4 rounded-lg w-full flex justify-center items-center ${
												isDragging
													? "bg-sidebarGray-500"
													: "bg-transparent"
											}`}
											onDrop={handleDrop}
											onDragOver={handleDragOver}
											onDragEnter={handleDragEnter}
											onDragLeave={handleDragLeave}
										>
											{isDragging ? (
												<p className="text-sidebarGrayLight-800 flex items-center justify-center gap-4">
													<DownloadIcon className="w-16 h-16" />
													Lassen Sie die Dateien los,
													um sie hochzuladen
												</p>
											) : (
												<label className="cursor-pointer text-gray-500">
													<span className="flex items-center justify-center gap-4">
														<ImageIcon className="w-16 h-16" />
														Ziehen Sie Ihre Bilder
														hierher oder klicken Sie
													</span>
													<input
														type="file"
														accept="image/*"
														multiple
														onChange={
															handleImageUpload
														}
														className="hidden"
													/>
												</label>
											)}
										</div>
										{selectedImages.length > 0 && (
											<div className="mt-4 grid grid-cols-2 gap-4">
												{selectedImages.map(
													(image, index) => {
														const imageUrl =
															URL.createObjectURL(
																image
															);
														return (
															<div
																key={index}
																className="relative"
															>
																<img
																	src={
																		imageUrl
																	}
																	alt={`preview-${index}`}
																	className="w-full h-auto object-cover rounded-md"
																/>
																<TrashIcon
																	className="p-2 w-8 h-8 text-sidebarGrayLight-400 rounded-md bg-sidebarGray-700 absolute right-1 top-1 hover:pointer"
																	onClick={() =>
																		removeImage(
																			index
																		)
																	}
																/>
															</div>
														);
													}
												)}
											</div>
										)}
									</Card>
								</div>
								<div className="grid grid-cols-1 w-2/5 gap-4">
									<Card className="flex h-full flex-grow flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">
											Kategorie
										</h3>
										<label htmlFor="select">
											Produktkategorie
										</label>
										<Select>
											<SelectTrigger
												className="w-full"
												id="select"
											>
												<SelectValue placeholder="Wählen Sie eine Kategorie aus" />
											</SelectTrigger>
											<SelectContent>
												<SelectGroup className="p-0 m-0 flex flex-col items-start justify-start">
													<SelectLabel className="p-0 m-0">
														Produktkategorie
													</SelectLabel>
													{Array.isArray(
														productGroups
													) &&
													productGroups.length > 0 ? (
														productGroups.map(
															(group) => (
																<SelectItem
																	className="p-0 m-0"
																	value={
																		group
																	}
																	key={group}
																>
																	{group}
																</SelectItem>
															)
														)
													) : (
														<></>
													)}
												</SelectGroup>
											</SelectContent>
										</Select>
									</Card>
									<Card className="flex flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">
											Preisinformationen
										</h3>
										<div className="w-full flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="basePrice">
													Basispreis
												</label>
												<div className="relative w-full">
													<div className="border bg-sidebarGray-500 border-sidebarGray-500 rounded-sm absolute top-1 left-1 px-2 py-[1px]">
														EUR
													</div>
													<Input
														id="basePrice"
														type="number"
														className="pl-16"
													></Input>
												</div>
											</span>
										</div>
										<div className="w-full flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="discount">
													Rabatte
												</label>
												<Input
													id="discount"
													placeholder="5%"
													type="number"
												></Input>
											</span>
										</div>
									</Card>
								</div>
							</section>
							<SheetFooter className="mt-12 flex flex-row items-end justify-end">
								<SheetClose className="flex items-center justify-center gap-2">
									<Button
										variant={"default"}
										className="flex items-center justify-center gap-4"
										onClick={() =>
											uploadProduct(
												articleId,
												weight,
												basePrice,
												quantity,
												sku,
												description,
												productName,
												productCategory,
												imageFiles
											)
										}
									>
										Produkt hochladen
										<UploadIcon className="w-4 h-4" />
									</Button>
									<Button variant="outline">Schließen</Button>
								</SheetClose>
							</SheetFooter>
						</SheetContent>
					</Sheet>
				</div>
			</div>
			<div className="flex items-center justify-center gap-4 px-2 py-6 lg:px-0">
				<div className="w-full flex items-center justify-center gap-4 px-2 lg:px-0">
					<Sheet>
						<SheetTrigger asChild>
							<Button
								variant="default"
								className="flex items-center justify-center gap-2"
							>
								Filter
								<MixerHorizontalIcon />
							</Button>
						</SheetTrigger>
						<SheetContent side={"left"} className="z-[70]">
							<SheetHeader>
								<SheetTitle className="text-2xl">
									Filter
								</SheetTitle>
							</SheetHeader>
							<div>
								{/* <Select
									value={selectedSort}
									onValueChange={handleSortChange}
								>
									<SelectTrigger className="w-full mt-6">
										<SelectValue placeholder="Sortierung" />
									</SelectTrigger>
									<SelectContent className="z-[71]">
										<SelectGroup>
											<SelectLabel>
												Sortierung
											</SelectLabel>
											<SelectItem value="price-asc">
												Preis aufsteigend
											</SelectItem>
											<SelectItem value="price-desc">
												Preis absteigend
											</SelectItem>
											<SelectItem value="searchTerm-asc">
												A - Z
											</SelectItem>
											<SelectItem value="searchTerm-desc">
												Z - A
											</SelectItem>
											<SelectItem value="available-asc">
												Bestand aufsteigend
											</SelectItem>
											<SelectItem value="available-desc">
												Bestand absteigend
											</SelectItem>
										</SelectGroup>
									</SelectContent>
								</Select> */}
								<Select
									value={selectedGroup}
									onValueChange={handleGroupChange}
								>
									<SelectTrigger className="w-full mt-6">
										<SelectValue placeholder="Warengruppe" />
									</SelectTrigger>
									<SelectContent className="z-[90]">
										<SelectGroup>
											<SelectLabel>
												Warengruppe
											</SelectLabel>
											{Array.isArray(productGroups) &&
											productGroups.length > 0 ? (
												productGroups.map((group) => (
													<SelectItem
														value={group}
														key={group}
													>
														{group}
													</SelectItem>
												))
											) : (
												<></>
											)}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<SheetFooter className="flex-col items-center justify-center mt-8">
								<SheetClose asChild>
									<Button type="submit" className="w-full">
										Schließen
									</Button>
								</SheetClose>
							</SheetFooter>
							<Button
								variant="ghost"
								className="w-full mt-4"
								onClick={handleResetFilters}
							>
								Filter zurücksetzen
							</Button>
						</SheetContent>
					</Sheet>
					<Input
						type="text"
						placeholder="Suche..."
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<Button
						className="w-max"
						variant={"default"}
						onClick={handleSearch}
					>
						Suche
					</Button>
				</div>
			</div>
			<div className=" flex items-center justify-start gap-2">
				<Badge className="bg-transparent h-8 w-0 p-0 m-0 shadow-none"></Badge>
				{selectedSort && (
					<Badge
						className="flex items-center justify-between gap-1 w-max"
						variant={"default"}
					>
						{sortBy === "price" && <p>Preis</p>}
						{sortBy === "searchTerm" && <p>Name</p>}
						{sortDirection === "DESC" && <p>absteigend</p>}
						{sortDirection === "ASC" && <p>aufsteigend</p>}
						<button
							onClick={() => (
								setSelectedSort(undefined),
								setSortBy(""),
								setSortDirection("")
							)}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
				{selectedGroup && (
					<Badge className="flex items-center justify-between gap-2 w-max">
						{selectedGroup}
						<button
							onClick={() => setSelectedGroup(undefined)}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
				{searchTerm && (
					<Badge className="flex items-center justify-between gap-2 w-max">
						{searchTerm}
						<button
							onClick={() => setSearchTerm("")}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
			</div>
			{Array.isArray(customProductList) &&
			customProductList.length > 0 ? (
				<div className="flex h-12 w-full items-center justify-end gap-4 -space-x-px text-sm">
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectFirst}
					>
						Start
					</button>
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectDec}
					>
						<ChevronLeftIcon />
						Zurück
					</button>
					{renderPageNumbers}
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectInc}
					>
						Weiter
						<ChevronRightIcon />
					</button>
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectLast}
					>
						Ende
					</button>
				</div>
			) : (
				<></>
			)}
			<Table className="px-8 z-40">
				<TableHeader className="sticky">
					<TableRow>
						<TableHead className="w-[100px]"></TableHead>
						<TableHead
							onClick={() => handleSortChange("articleId")}
							className="cursor-pointer w-[100px]"
						>
							<div className="flex items-center whitespace-nowrap">
								Art.-Nr.
								{getSortIcon("articleId")}
							</div>
						</TableHead>
						<TableHead className="w-[180px]">Status</TableHead>
						<TableHead
							onClick={() => handleSortChange("searchTerm")}
							className="cursor-pointer flex items-center"
						>
							<div className="flex items-center">
								Name
								{getSortIcon("searchTerm")}
							</div>
						</TableHead>
						<TableHead>Kategorie</TableHead>
						<TableHead
							onClick={() => handleSortChange("price")}
							className="cursor-pointer w-[100px] px-0"
						>
							<div className="flex items-center text-right justify-end">
								Preis
								{getSortIcon("price")}
							</div>
						</TableHead>
						<TableHead
							onClick={() => handleSortChange("available")}
							className="cursor-pointer w-[100px] text-right px-0"
						>
							<div className="flex items-center justify-end">
								Bestand
								{getSortIcon("available")}
							</div>
						</TableHead>
						<TableHead className="text-right w-[150px]"></TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{Array.isArray(customProductList) &&
					customProductList.length > 0 ? (
						customProductList.map((product) => (
							<TableRow
								key={product.articleId}
								className="dark:text-white"
							>
								<ProductImageCell
									imageUrl={
										product.images?.[0]?.imageUrl || ""
									}
									articleId={product.articleId}
								/>
								<TableCell className="font-medium text-center">
									{product.articleId}
								</TableCell>
								<TableCell className="font-medium flex items-center h-full justify-start gap-2">
									{product.active ? (
										<Badge className="bg-green-950 text-green-300">
											Aktiv
										</Badge>
									) : (
										<Badge className="bg-gray-900 text-gray-300">
											Inaktiv
										</Badge>
									)}
									{product.showPrice ? (
										<Badge className="bg-blue-950 text-blue-300">
											Festpreis
										</Badge>
									) : (
										<Badge className="bg-orange-950 text-orange-300">
											Anfrage
										</Badge>
									)}
								</TableCell>
								<TableCell className="font-medium">
									{product.searchTerm}
								</TableCell>
								<TableCell>{product.productGroup}</TableCell>
								<TableCell className="font-medium text-right">
									{product.price}€
								</TableCell>
								<TableCell className="text-right">
									{product.available}
								</TableCell>
								<TableCell className="h-full flex items-center justify-center gap-2">
									<Drawer>
										<DrawerTrigger
											asChild
											className="w-12 h-12"
										>
											<Button variant="outline">
												<Pencil1Icon className="w-5 h-5" />
											</Button>
										</DrawerTrigger>
										<DrawerContent className="h-[95vh] px-12">
											<DrawerHeader className="flex flex-col">
												<DrawerTitle className="text-3xl flex flex-col items-start justify-start p-0 m-0">
													{product.searchTerm}
												</DrawerTitle>
												<DrawerDescription>
													{product.articleId}
												</DrawerDescription>
											</DrawerHeader>
											<section className="flex items-start justify-start gap-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-sidebarGray-700 scrollbar-track-sidebarGray-800 custom-scrollbar overflow-x-hidden">
												<div className="w-2/3 flex flex-col items-start justify-start gap-4">
													<Card className="w-full flex flex-col py-8 pl-16 pr-16 items-start justify-start">
														<div className="flex flex-col w-full items-start gap-1.5 pl-1">
															<span className="text-base text-gray-500 w-full">
																Bilder hochladen
															</span>
															<div className="flex items-center justify-center gap-8 w-full">
																<input
																	className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-sidebarGray-700"
																	id="file_input"
																	type="file"
																	multiple
																	onChange={(
																		e
																	) =>
																		handleImageChange(
																			product.articleId,
																			e
																				.target
																				.files
																		)
																	}
																/>
																{loading && (
																	<div className="grid w-full place-items-center overflow-x-scroll rounded-lg lg:overflow-visible">
																		<svg
																			className="text-gray-300 animate-spin"
																			viewBox="0 0 64 64"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																			width="24"
																			height="24"
																		>
																			<path
																				d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
																				stroke="currentColor"
																				stroke-width="5"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																			></path>
																			<path
																				d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
																				stroke="currentColor"
																				stroke-width="5"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																				className="text-gray-900"
																			></path>
																		</svg>
																	</div>
																)}
															</div>
														</div>
													</Card>
													<Card className="w-full flex flex-col py-8 pl-16 pr-16 items-start justify-start">
														<span className="text-base text-gray-500 pl-1 pt-1">
															Produktbilder
														</span>
														<div className="grid grid-cols-3 gap-2 mt-4">
															{product.images
																.length ===
															0 ? (
																<ImageIcon className="w-96 h-96 text-sidebarGray-500" />
															) : (
																product.images.map(
																	(
																		image: CustomImageFile
																	) => (
																		<div
																			key={
																				image.id
																			}
																			className="relative border-sidebarGray-500 border-[1px]"
																		>
																			<img
																				src={`${image.imageUrl}`}
																				alt={
																					product.searchTerm
																				}
																				className="w-full h-full max-h-[250px] object-contain"
																			/>
																		</div>
																	)
																)
															)}
														</div>
													</Card>
												</div>
												<div className="flex flex-col w-1/3 gap-4">
													<Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start">
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																ID
															</span>
															<p className="text-lg">
																{
																	product.articleId
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Produktname
															</span>
															{product.searchTerm ? (
																<p className="text-lg">
																	{
																		product.searchTerm
																	}
																</p>
															) : (
																<p>-</p>
															)}
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Beschreibung
															</span>
															{product.description ? (
																<p className="text-lg">
																	{
																		product.description
																	}
																</p>
															) : (
																<p>-</p>
															)}
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Details
															</span>
															<p className="text-lg">
																{
																	product.additionalInfo
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Bestand
															</span>
															<p className="text-lg">
																{
																	product.available
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Gewicht
															</span>
															<p className="text-lg">
																{product.weight}
																&nbsp;
																{
																	product.weightUnit
																}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Preis
															</span>
															<p className="text-lg">
																{product.price}
																&nbsp;
																{
																	product.basePriceUnit
																}
															</p>
														</div>
													</Card>
												</div>
											</section>
											<DrawerFooter className="flex flex-row items-end justify-end">
												<DrawerClose>
													<Button variant="outline">
														Schließen
													</Button>
												</DrawerClose>
											</DrawerFooter>
										</DrawerContent>
									</Drawer>
									<Popover>
										<PopoverTrigger
											asChild
											className="w-12 h-12"
										>
											<Button variant="outline">
												<DotsHorizontalIcon className="w-5 h-5" />
											</Button>
										</PopoverTrigger>
										<PopoverContent className="w-56">
											<div className="flex items-center justify-start gap-2">
												<StarIcon />
												<h1>Schnellzugriff</h1>
											</div>
										</PopoverContent>
									</Popover>
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={8}
								className="text-center py-12 text-xl"
							>
								Es wurden keine Produkte gefunden
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			{Array.isArray(customProductList) &&
			customProductList.length > 0 ? (
				<div className="flex h-12 w-full items-center justify-center gap-4 -space-x-px text-sm py-12">
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectFirst}
					>
						Start
					</button>
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectDec}
					>
						<ChevronLeftIcon />
						Zurück
					</button>
					{renderPageNumbers}
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectInc}
					>
						Weiter
						<ChevronRightIcon />
					</button>
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectLast}
					>
						Ende
					</button>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default CustomProductTable;
